import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setErrors((prev) => prev.filter((error) => error !== 'captcha'));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newErrors = [];
    setSuccessMessage('');

    if (!name) {
      newErrors.push('Por favor, ingresa tu nombre.');
    }

    if (!email) {
      newErrors.push('Por favor, ingresa un correo electrónico válido.');
    }

    if (!message) {
      newErrors.push('Por favor, escribe tu mensaje.');
    }

    if (!captchaValue) {
      newErrors.push('Por favor, completa el captcha.');
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    console.log('Form submitted with:', { name, email, message });
    setName('');
    setEmail('');
    setMessage('');
    setCaptchaValue(null);
    setErrors([]);
    setSuccessMessage('¡Gracias por contactarnos! Tu mensaje ha sido enviado.');
    setShowCaptcha(false);
  };

  return (
    <div className="max-w-3xl mx-auto p-6 flex-1 basis-auto min-h-full">
      <h1 className="text-3xl font-bold mb-4 text-white">
        ¡Mantengámonos en contacto!
      </h1>
      <p className="text-gray-300 mb-6">
        Siempre estaremos encantados de responder a sus preguntas y escuchar
        sugerencias. Si tienes una petición, sugerencia o simplemente un mensaje
        interesante, escríbenos. ¡Estamos en contacto y listos para ayudar!
      </p>

      <form
        id="contactForm"
        className="flex mx-auto flex-col gap-6 max-w-lg items-center"
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          id="name"
          placeholder="Tu nombre"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="w-full bg-gray-800 text-white placeholder-gray-400 px-4 py-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="email"
          id="email"
          placeholder="Tu correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full bg-gray-800 text-white placeholder-gray-400 px-4 py-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <textarea
          id="message"
          placeholder="Tu mensaje"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          className="w-full bg-gray-800 text-white placeholder-gray-400 px-4 py-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
        ></textarea>

        {showCaptcha && (
          <div
            className={`mt-4 transform transition-transform duration-500 ${
              showCaptcha ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
            }`}
          >
            <ReCAPTCHA
              className="flex justify-center"
              sitekey="6LeRDqQqAAAAAM3qJ4ncFWUDYlWg0BfxeLyV5S4R"
              onChange={handleCaptchaChange}
            />
          </div>
        )}

        <div className="flex gap-10">
          <button
            type="submit"
            className="bg-blue-500 text-white font-bold px-8 py-3 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 max-w-xs mx-auto"
            onMouseEnter={() => setShowCaptcha(true)}
          >
            Enviar
          </button>
          <a
            href="#"
            className="inline-block px-8 py-3 bg-[#03206C8F] hover:opacity-80 transition-all duration-100 text-white rounded-lg font-bold text-lg "
          >
            Regresar
          </a>
        </div>

        {errors.length > 0 && (
          <div className="mt-4 text-red-500 text-sm">
            {errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}

        {successMessage && (
          <div className="mt-4 text-green-500 text-sm text-center">
            {successMessage}
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactUs;
