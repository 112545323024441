import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

function SubscriptionForm() {
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setErrors((prev) => prev.filter((error) => error !== 'captcha'));
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value && !showCaptcha) {
      setShowCaptcha(true); // Показати капчу
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newErrors = [];
    setSuccessMessage('');
    if (!email) {
      newErrors.push('Por favor, ingresa un correo electrónico válido.');
    }

    if (!consent) {
      newErrors.push('Debes aceptar los términos para continuar.');
    }

    if (!captchaValue) {
      newErrors.push('Por favor, completa el captcha.');
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    console.log('Form submitted with:', { email });
    setEmail('');
    setConsent(false);
    setCaptchaValue(null);
    setErrors([]);
    setSuccessMessage('¡Gracias por suscribirte! Tu mensaje ha sido enviado.');
    setShowCaptcha(false);
  };

  return (
    <div className="bg-gray-900 p-6 mx-4 rounded-lg">
      <h3 className="text-lg text-white font-semibold text-center">
        Suscríbete al boletín
      </h3>
      <p className="text-center text-gray-400">
        Sé el primero en conocer novedades, ofertas especiales y bonificaciones
        exclusivas de nuestros socios en el mundo de iGaming.
      </p>

      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="flex items-center space-x-2">
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="example@domain.com"
            className="flex-1 px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring focus:ring-[#0466C8]"
            required
          />

          <button
            type="submit"
            className="py-2 px-4 bg-[#0466C8] text-white rounded-lg font-semibold hover:opacity-80 focus:outline-none focus:ring focus:ring-[#0466C8]"
          >
            Suscribir
          </button>
        </div>

        <div className="flex items-center space-x-2 mt-4">
          <input
            type="checkbox"
            id="consent"
            checked={consent}
            onChange={(e) => setConsent(e.target.checked)}
            className="text-[#0466C8] focus:ring-[#0466C8]"
            required
          />
          <label htmlFor="consent" className="text-sm text-gray-400">
            Acepto recibir notificaciones sobre novedades y ofertas especiales
          </label>
        </div>

        {/* Капча з анімацією */}
        {showCaptcha && (
          <div
            className={`mt-4 transform transition-transform duration-500 ${
              showCaptcha ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
            }`}
          >
            <ReCAPTCHA
              className="flex justify-center"
              sitekey="6LeRDqQqAAAAAM3qJ4ncFWUDYlWg0BfxeLyV5S4R"
              onChange={handleCaptchaChange}
            />
          </div>
        )}

        {errors.length > 0 && (
          <div className="mt-4 text-red-500 text-sm">
            {errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}

        {successMessage && (
          <div className="mt-4 text-green-500 text-sm text-center">
            {successMessage}
          </div>
        )}

        <p className="text-xs text-center text-gray-400 mt-4">
          Al suscribirte al boletín, aceptas nuestra
          <Link
            to={'/privacy-policy'}
            className="underline mx-1 text-[#0466C8]"
          >
            Política de Privacidad
          </Link>
          y consientes el procesamiento de tus datos personales por parte de{' '}
          <strong>PLEI KRIEITIV TOV</strong>.
        </p>
      </form>
    </div>
  );
}

export default SubscriptionForm;
